import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DocumentReference } from '@angular/fire/firestore';
import { passwordValidator } from '../_validator/password.validator';
import { Auth, GoogleAuthProvider, browserSessionPersistence, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, signInWithPopup, updateProfile } from '@angular/fire/auth';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../_service/user.service';
import { catchError, combineLatest, from, map, mergeMap, of } from 'rxjs';
import { Space } from '../_model/space';
import { SpaceService } from '../_service/space.service';
import { LoadingOverlayService } from '../_service/loading-overlay.service';
import { isErrorInfo } from '@k-bit-modesty/share-models';
import { MatSnackBar } from '@angular/material/snack-bar';

/** サービス開始リクエストフォーム */
interface EmailSignInForm {
  /** メールアドレス */
  email: FormControl<string>,
  /** 名前 */
  name: FormControl<string>,
  /** パスワード */
  password: FormControl<string>
}

/** スペース設定 */
interface SpaceSettingForm {
  /** 名前 */
  name: FormControl<string>,
  /** 電話番号 */
  telephone: FormControl<string>,
  /** 同意済み */
  accepted: FormControl<boolean>
}

/** ログイン形式設定状態 */
const loginStyle = {
  /** ログイン形式未設定 */
  NOT_SETTING: 'not-setting',
  /** Emailとパスワードでのログイン */
  EMAIL_AND_PASSWORD: 'email-and-password',
  /** Google */
  GOOGLE_LOGIN: 'google-login'
} as const;
type loginStyle = typeof loginStyle[keyof typeof loginStyle];

@Component({
  selector: 'contract-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

  constructor(
    /** フォームビルダー */
    private _fb: FormBuilder,
    /** Auth */
    private _auth: Auth,
    /** Router */
    private _router: Router,
    /** ActivatedRoute */
    private _route: ActivatedRoute,
    /** ユーザーサービス */
    private _userService: UserService,
    /** スペースサービス */
    private _spaceService: SpaceService,
    /** OverLay */
    private _overlay: LoadingOverlayService,
    /** SnackBar */
    private _snackbar: MatSnackBar,
    /** ngZone */
    private ngZone: NgZone
  ) {
  }

  /** スペース設定フォーム */
  spaceSettingForm = this._fb.nonNullable.group<SpaceSettingForm>({
    name: this._fb.nonNullable.control('', Validators.required),
    telephone: this._fb.nonNullable.control('', [Validators.required, Validators.pattern(/(^0[789]0-\d{4}-\d{4}$)|(^0[789]0\d{8}$)|(^0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4}$)|(^0\d{9}$)/)]),
    accepted: this._fb.nonNullable.control(false, Validators.requiredTrue)
  });

  /** リクエストフォーム */
  emailSignInForm = this._fb.nonNullable.group<EmailSignInForm>({
    email: this._fb.nonNullable.control('', [Validators.required, Validators.email]),
    name: this._fb.nonNullable.control('', [Validators.required]),
    password: this._fb.nonNullable.control('', [Validators.required, passwordValidator])
  });

  /** GoogleAuthProvider */
  private _googleAuthProvider = new GoogleAuthProvider();
  /** ログイン形式設定 */
  loginStyleSetting?: loginStyle;

  ngOnInit(): void {
    this.loginStyleSetting = loginStyle.NOT_SETTING;
    // 表示したことをGoogleAnalyticsに送信
    gtag('event', 'page_view', {
      page_title: 'スタート画面',
    });
  }

  /** Emailアドレスで作成ボタンをクリック */
  onClickCreateUserWithEmailAndPassword() {
    gtag('event', 'ボタン押下', {
      target: 'Emailアドレスで作成',
    });

    // Emailとパスワードでのログイン状態にする
    this.loginStyleSetting = loginStyle.EMAIL_AND_PASSWORD;
  }

  /** 戻るボタンクリック */
  onClickBack() {
    gtag('event', 'ボタン押下', {
      target: '戻る',
    });
    // 未指定状態
    this.loginStyleSetting = loginStyle.NOT_SETTING;
  }
  
  onClickSignInWithGoogle() {
    gtag('event', 'ボタン押下', {
      target: 'Googleでサインイン',
    });
    // オーバーレイ表示
    this._overlay.show();
    const spaceSetting = this.spaceSettingForm.value;
    // フォームをロックする
    this.spaceSettingForm.disable();
    // グーグルログインの状態にする
    this.loginStyleSetting = loginStyle.GOOGLE_LOGIN;
    // サインイン
    from(signInWithPopup(this._auth, this._googleAuthProvider))
    .pipe(
      mergeMap(userCred => {
        // オーバーレイ削除
        this._overlay.close();
        // メールが送信される旨の画面
        this._router.navigate(['after-sign-in-google-verification'], {
          relativeTo: this._route
        });
        return this._createSpace(
          spaceSetting.name!, spaceSetting.telephone!
        );
      }),
      mergeMap(res => this._auth.signOut())
    )
    .subscribe({
      next: () => {
        // GoogleでサインアップしたことをGoogleAnalyticsに送信
        gtag('event', 'sign_up', {
          method: 'Google',
        });
      },
      error: err => {
        // オーバーレイ削除
        this._overlay.close();
        console.error(err);
        // エラーのときは未指定状態に戻す
        this.loginStyleSetting = loginStyle.NOT_SETTING;
        // フォームを有効化
        this.spaceSettingForm.enable();
        if(err.message === "スペース所属済みエラー"){
          gtag('event', 'exception', {
            description: err.message,
            fatal: false
          });
          this._snackbar.open("すでにスペースに参加済みのアカウントです。", "OK", {duration: 5000});
        } else {
          gtag('event', 'exception', {
            description: err.message,
            fatal: true
          })
          this._snackbar.open("不明なエラーが発生しました。", "OK", {duration: 5000});
        }  
      }
    });
  }

  onClickSubmit() {
    // ボタン押下をGoogleAnalyticsに送信
    gtag('event', 'ボタン押下', {
      target: 'スペース作成',
    });
    // オーバーレイ表示
    this._overlay.show();
    // フォームが有効なとき
    if (this.spaceSettingForm.valid && this.emailSignInForm.valid) {
      const spaceSetting = this.spaceSettingForm.value;
      const emailSignInInfo = this.emailSignInForm.value;
      // フォームをロックする
      this.spaceSettingForm.disable();
      this.emailSignInForm.disable();
      // 永続性を設定する
      from(this._auth.setPersistence(browserSessionPersistence))
        .pipe(
          // ユーザ作成
          mergeMap(() => createUserWithEmailAndPassword(this._auth, emailSignInInfo.email!, emailSignInInfo.password!)),
          // ユーザ情報更新
          mergeMap(userCred => combineLatest([
            of(userCred),
            updateProfile(userCred.user, {
              displayName: userCred.user.displayName ?? emailSignInInfo.name,
              photoURL: userCred.user.photoURL ?? `${environment.clientDomain}/assets/images/default_icon.png`
            })
          ])
            .pipe(
              map(([userCred]) => userCred)
            )
          ),
          // ユーザ新規作成処理でエラーがあった時
          catchError(err => {
            // すでに使用済みエラー
            if (err.code === 'auth/email-already-in-use') {
              // すでに使われている場合はログインする
              return from(signInWithEmailAndPassword(this._auth, emailSignInInfo.email!, emailSignInInfo.password!))
                .pipe(
                  mergeMap(userCred => {
                    return combineLatest([
                      of(userCred),
                      this._userService.getUserFromUID(userCred.user.uid)
                    ]);
                  }),
                  mergeMap(([userCred, userInfo]) => {
                    // ユーザデータがない時
                    if (userInfo === undefined) {
                      return of(userCred);
                    } else if (userInfo.mainSpace instanceof DocumentReference) {
                      // メインスペースあるとき
                      return combineLatest([
                        of(userCred),
                        from(Space.getFromRef(userInfo.mainSpace))
                      ])
                        .pipe(
                          map(([userCred, space]) => {
                            // メインスペースが存在し、ユーザが参加していたら
                            if (space instanceof Space
                              && space.users.some(ref_1 => ref_1.id === userCred.user.uid)) {
                              throw new Error("user-already-has-main-space");
                            }
                            // ユーザが参加していなかったら
                            else {
                              // ユーザ情報を渡す
                              return userCred;
                            }
                          })
                        );
                    } else {
                      return of(userCred);
                    }
                  })
                )
            }
            else {
              throw err;
            }
          }),
          // ログイン後処理
          mergeMap(userCred => {
            // オーバーレイ削除
            this._overlay.close();
            // メールが送信される旨の画面
            this._router.navigate(['after-send-email-verification', {
              relativeTo: this._route
            }]);
            return this._createSpace(
              spaceSetting.name!, spaceSetting.telephone!
              )
              .pipe(
                map(res => userCred)
              );
          })
        )
        .subscribe({
          next: res => {
            // GoogleAnalyticsに送信
            gtag('event', 'sign_up', {
              method: 'Email',
            });
          },
          error: err => {
            // オーバーレイ削除
            this._overlay.close();
            console.error(err);
            // フォームを有効化
            this.spaceSettingForm.enable();
            this.emailSignInForm.enable();

            // エラーメッセージがany型なのでstring型に変換
            const errMessage = err.message as string;

            // すでにメインスペースが設定されたアカウントの場合
            if (errMessage === "user-already-has-main-space") {
              gtag('event', 'exception', {
                description: errMessage,
                fatal: false
              });
              this.ngZone.run(() => {
                // 画面の更新が行われない為NgZoneを使用
                this._snackbar.open("すでにスペースに参加済みのアカウントです。", "OK", {duration: 5000});
              });
            }
            // 入力された認証情報が間違っていた場合
            else if (
              errMessage.includes("auth/wrong-password")
              || errMessage.includes("auth/invalid-email")
            ) {
              gtag('event', 'exception', {
                description: errMessage,
                fatal: false
              });
              // 画面の更新が行われない為NgZoneを使用
              this.ngZone.run(() => {
                this._snackbar.open("入力された認証情報に誤りがあります。", "OK", {duration: 5000});
              });
            }
            // 入力された認証情報が無効だった場合
            else if (errMessage.includes("auth/user-disabled")) {
              gtag('event', 'exception', {
                description: errMessage,
                fatal: false
              });
              // 画面の更新が行われない為NgZoneを使用
              this.ngZone.run(() => {
                this._snackbar.open("入力された認証情報は無効です。", "OK", {duration: 5000});
              });
            }
            // 上記以外のエラーだった場合
             else {
              gtag('event', 'exception', {
                description: errMessage,
                fatal: false
              });
              this.ngZone.run(() => {
                // 画面の更新が行われない為NgZoneを使用
                this.ngZone.run(() => {
                  this._snackbar.open("不明なエラーが発生しました。", "OK", {duration: 5000});
                });
              })
            }
          }
        });
    }
  }

  private _createSpace(spaceName: string, userTel: string) {
    return this._spaceService.create(
      spaceName, userTel
    )
    .pipe(
      map(res => {
        if(isErrorInfo(res)) {
          // エラーをスロー
          throw new Error(res.message);
        }
        else {
          return res;
        }
      })
    );
  }
}
