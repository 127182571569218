import { Injectable } from '@angular/core';
import { Observable, from, map} from 'rxjs';
import { ErrorInfo, SpaceCreateRequest, SpaceCreateResponse } from '@k-bit-modesty/share-models';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpaceService {

  constructor(
    /** Function */
    private _functions: Functions
  ) { }

  create(name: string, userTel: string): Observable<SpaceCreateResponse | ErrorInfo> {
    /** リクエストデータ */
    const req: SpaceCreateRequest = {
      name,
      isPersonal: false,
      isPublic: false,
      userTel,
      description: `${name}のスペースです。`
    };

    /** 実行関数 */
    const func = httpsCallable<SpaceCreateRequest, SpaceCreateResponse | ErrorInfo>(
      this._functions, 
      environment.functions.space.create,
      {
        timeout: 540000
      }
    );
    return from(func(req))
      .pipe(
        map(res => res.data)
      );
  }

}
